import { ReactText } from 'react';

import { IWithThemeProps } from 'types';

export type Header = {
    label: string;
    id: string;
    sortable?: boolean;
};

export type Row = {
    id: ReactText;
    [name: string]: ReactText | JSX.Element;
};

export type SortOrderTypes = {
    ASC: 'ASC';
    DESC: 'DESC';
    INACTIVE: 'INACTIVE';
};

export const SORT_ORDER_TYPES: SortOrderTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
    INACTIVE: 'INACTIVE',
};

export type SortOrderType = SortOrderTypes[keyof SortOrderTypes];

export type SortOptions = {
    sortBy: string | number;
    sortOrder: SortOrderType;
};

export interface ITableProps extends IWithThemeProps {
    /**
     * Optional sort options, required to enable column sorting
     */
    sortOptions?: SortOptions;
    /**
     * Handles onClick event of TableSorter within header
     */
    onSort?: (sortOptions: SortOptions) => void;
    /**
     * Optional value for cells without a found header/row pair
     */
    emptyCellValue?: ReactText | JSX.Element;
    /**
     * Provides headers for table
     */
    headers: Header[];
    /**
     * Provides rows for table
     */
    rows: Row[];
}

export interface IExpandedContentToggleProps extends IWithThemeProps {
    /**
     * Toggles orientation of Caret icon
     */
    expanded?: boolean;
    /**
     * Sets the text within the toggler
     */
    label?: string;
    /**
     * Handles onClick of toggler
     */
    onClick: () => void;
}

export interface ITableSorterProps extends IWithThemeProps {
    /**
     * Sort options to set the enabled/disabled state as well as caret orientation
     */
    sortOptions: SortOptions;
    /**
     * Current header to match sort options against
     */
    header: Header;
}
