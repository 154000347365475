import React, { FC, useState } from 'react';

import { withThemeContext, getFixedSizeIcon } from '../../../tools/helpers';
import {
    Input,
    IconContainer,
    InputAssistiveText,
    InputContainer,
    InputLabel,
    InputWrapper,
} from '../styles';
import { ITextFieldProps, TEXT_FIELD_TYPES, VARIANT_TYPES } from '../types';

export const TextFieldPC: FC<ITextFieldProps> = ({
    assistiveText,
    disabled = false,
    hasError = false,
    icon = null,
    iconOnClick,
    inputProps = {},
    label,
    multiline = false,
    name,
    onChange,
    required = false,
    rows = 4,
    style = {},
    type = TEXT_FIELD_TYPES.TEXT,
    value = '',
    variant = VARIANT_TYPES.FILLED,
}) => {
    const [focused, setFocused] = useState(false);

    const fixedSizeIcon = icon && getFixedSizeIcon(icon);
    let iconContent;
    if (fixedSizeIcon) {
        iconContent = (
            <IconContainer onClick={iconOnClick} as={iconOnClick ? 'button' : 'span'}>
                {fixedSizeIcon}
            </IconContainer>
        );
    }

    const handleOnBlur = () => setFocused(false);

    const handleOnFocus = () => !disabled && setFocused(true);

    const newLabel = required ? `${label} *` : label;

    const hasValue = Boolean(value !== null && value !== '');

    return (
        <InputContainer key={`container-${name}`}>
            <InputWrapper
                key={`wrapper-${name}`}
                disabled={disabled}
                variant={variant}
                style={style}
                focused={focused}
                hasError={hasError}
            >
                <Input
                    key={`input-${name}`}
                    as={multiline ? 'textarea' : 'input'}
                    disabled={Boolean(disabled)}
                    name={name}
                    onChange={!disabled ? onChange : () => null}
                    type={multiline ? undefined : type}
                    value={value}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    required={required}
                    rows={multiline ? rows : undefined}
                    withIcon={Boolean(iconContent)}
                    {...inputProps}
                />
                {iconContent}
                <InputLabel
                    key={`label-${name}`}
                    htmlFor={name}
                    focused={focused}
                    hasError={hasError}
                    hasValue={hasValue}
                >
                    {newLabel}
                </InputLabel>
            </InputWrapper>
            {assistiveText && (
                <InputAssistiveText
                    key={`assistive-${name}`}
                    hasError={hasError}
                    role={hasError ? 'alert' : undefined}
                >
                    {assistiveText}
                </InputAssistiveText>
            )}
        </InputContainer>
    );
};

export const TextFieldWT = withThemeContext<ITextFieldProps>(TextFieldPC);
