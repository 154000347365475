import styled from 'styled-components';

import { themePropertyFetcher } from '../../tools/helpers';
import { IGenericStyledComponentProps, COLOR_VARIANT_TYPES } from '../../types';
import { VARIANT_TYPES, VariantType } from './types';

interface IInputWrapperProps extends IGenericStyledComponentProps {
    disabled: boolean;
    variant: VariantType;
    style: object;
    focused: boolean;
    hasError: boolean;
}

interface IDefaultInputProps extends IGenericStyledComponentProps {
    disabled: boolean;
    hasValue?: boolean;
}

interface IInputTextareaProps extends IDefaultInputProps {
    as?: 'input' | 'textarea';
    rows?: number;
    type?: string;
    withIcon?: boolean;
}

interface IInputAssistiveTextProps extends IGenericStyledComponentProps {
    hasError: boolean;
}

interface ISelectIconProps extends IGenericStyledComponentProps {
    disabled: boolean;
    focused: boolean;
}

interface IIconContainerProps extends IGenericStyledComponentProps {
    onClick?: () => void;
    as?: 'button' | 'span';
}

interface IInputLabel extends IGenericStyledComponentProps {
    focused: boolean;
    hasValue?: boolean;
    hasError: boolean;
}

export const InputContainer = styled.div({
    position: 'relative',
    lineHeight: 'normal',
});

export const InputWrapper = styled.div<IInputWrapperProps>((props): any => ({
    position: 'relative',
    overflow: 'hidden',

    ...getVariantStyles(props),

    /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
    transition: `all ease ${themePropertyFetcher(props.theme, ['transitionSpeeds', 1])}`,
}));

export const Select = styled.select<IDefaultInputProps>((props): any => ({
    zIndex: 3,

    ...getCommonStyles(props),

    appearance: 'none',

    position: 'relative',
    height: '48px',
    width: '100%',

    paddingTop: themePropertyFetcher(props.theme, ['space', 5]),
    paddingRight: themePropertyFetcher(props.theme, ['space', 12]),
    paddingLeft: themePropertyFetcher(props.theme, ['space', 4]),
    paddingBottom: themePropertyFetcher(props.theme, ['space', 0]),

    backgroundColor: 'transparent',
    border: '0 none',
    borderRadius: 0,

    fontWeight: themePropertyFetcher(props.theme, ['fontWeights', 'bold']),
    color:
        props.hasValue === false
            ? 'transparent'
            : themePropertyFetcher(props.theme, ['colors', 'greys', props.disabled ? 600 : 800]),

    boxSizing: 'border-box',

    cursor: props.disabled ? 'not-allowed' : 'pointer',

    '&:focus': {
        outline: 'none',
    },
}));

export const Input = styled.input<IInputTextareaProps>((props): any => {
    return {
        zIndex: 2,

        ...getCommonStyles(props),

        position: 'relative',
        minHeight: '48px',
        width: props.withIcon
            ? `calc(100% - ${themePropertyFetcher(props.theme, ['space', 12])}px)`
            : '100%',
        lineHeight: 1.2,

        paddingTop: themePropertyFetcher(props.theme, ['space', 5]),
        paddingRight: themePropertyFetcher(props.theme, ['space', 4]),
        paddingLeft: themePropertyFetcher(props.theme, ['space', 4]),
        paddingBottom: themePropertyFetcher(props.theme, ['space', 0]),

        backgroundColor: 'transparent',
        border: '0 none',

        fontWeight: themePropertyFetcher(props.theme, ['fontWeights', 'bold']),
        color: themePropertyFetcher(props.theme, ['colors', 'greys', props.disabled ? 600 : 800]),

        boxSizing: 'border-box',

        cursor: props.disabled ? 'not-allowed' : 'text',

        '&:focus': {
            outline: 'none',
        },

        // Append to allow override of CSS properties
        ...getTypeSpecificStyles(props),
    };
});

export const InputLabel = styled.label<IInputLabel>((props): any => {
    const focusedState = Boolean(props.focused || props.hasValue);

    let color = themePropertyFetcher(props.theme, ['colors', 'greys', 600]);
    if (props.hasError && focusedState) {
        color = themePropertyFetcher(props.theme, ['colors', COLOR_VARIANT_TYPES.ERROR, 'main']);
    } else if (!focusedState) {
        color = themePropertyFetcher(props.theme, ['colors', 'greys', 700]);
    }

    return {
        zIndex: 1,

        ...getCommonStyles(props),

        position: 'absolute',

        left: 0,
        top: 0,
        right: 0,
        bottom: 'auto',

        height: '48px',
        lineHeight: 1,

        paddingTop: themePropertyFetcher(props.theme, ['space', focusedState ? 0 : 3]),
        paddingRight: themePropertyFetcher(props.theme, ['space', 4]),
        paddingLeft: themePropertyFetcher(props.theme, ['space', 4]),
        paddingBottom: themePropertyFetcher(props.theme, ['space', focusedState ? 5 : 3]),

        fontSize: focusedState ? themePropertyFetcher(props.theme, ['fontSizes', 1]) : null,
        fontWeight: themePropertyFetcher(props.theme, [
            'fontWeights',
            focusedState ? 'default' : 'bold',
        ]),

        color,

        display: 'flex',
        alignItems: 'center',

        pointerEvents: 'none',

        boxSizing: 'border-box',
    };
});

export const InputAssistiveText = styled.div<IInputAssistiveTextProps>((props): any => {
    return {
        padding: `${themePropertyFetcher(props.theme, [
            'space',
            1,
        ])}px ${themePropertyFetcher(props.theme, ['space', 4])}px 0`,

        fontSize: themePropertyFetcher(props.theme, ['fontSizes', 1]),
        fontWeight: themePropertyFetcher(props.theme, ['fontWeights', 'default']),

        color: props.hasError
            ? themePropertyFetcher(props.theme, ['colors', COLOR_VARIANT_TYPES.ERROR, 'main'])
            : themePropertyFetcher(props.theme, ['colors', 'greys', 600]),
    };
});

export const SelectIcon = styled.div<ISelectIconProps>((props): any => {
    let color = themePropertyFetcher(props.theme, ['colors', 'greys', 800]);
    if (props.disabled) {
        color = themePropertyFetcher(props.theme, ['colors', 'greys', 600]);
    } else if (props.focused) {
        color = themePropertyFetcher(props.theme, ['colors', COLOR_VARIANT_TYPES.PRIMARY, 'focus']);
    }

    return {
        zIndex: 3,

        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,

        display: 'flex',
        alignItems: 'center',

        paddingLeft: themePropertyFetcher(props.theme, ['space', 3]),
        paddingRight: themePropertyFetcher(props.theme, ['space', 3]),

        color,

        pointerEvents: 'none',
    };
});

export const IconContainer = styled.span<IIconContainerProps>((props): any => {
    let buttonDefaults = {};
    if (props.as === 'button') {
        buttonDefaults = {
            border: '0 none',
            appearance: 'none',
            backgroundColor: 'transparent',
            '&:focus': {
                outline: 'none',
            },
        };
    }

    return {
        zIndex: 3,

        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,

        display: 'flex',
        alignItems: 'center',

        paddingLeft: themePropertyFetcher(props.theme, ['space', 3]),
        paddingRight: themePropertyFetcher(props.theme, ['space', 3]),

        cursor: props.onClick ? 'pointer' : 'inherit',

        ...buttonDefaults,
    };
});

/**
 * Provided "common" styles for Input & Label components
 * @param props
 */
const getCommonStyles = (props: IGenericStyledComponentProps) => {
    return {
        color: themePropertyFetcher(props.theme, ['colors', 'greys', 600]),

        fontFamily: themePropertyFetcher(props.theme, ['fontFamily']),
        fontSize: themePropertyFetcher(props.theme, ['fontSizes', 3]),

        opacity: 1,
        transition: `all ease ${themePropertyFetcher(props.theme, ['transitionSpeeds', 1])}`,
    };
};

/**
 * Get variant styles based on props.variant
 */
const getVariantStyles = (props: IInputWrapperProps) => {
    if (props.variant === VARIANT_TYPES.OUTLINED) {
        let boxShadowColor = themePropertyFetcher(props.theme, ['colors', 'greys', 500]);
        if (props.hasError) {
            boxShadowColor = themePropertyFetcher(props.theme, [
                'colors',
                COLOR_VARIANT_TYPES.ERROR,
                'main',
            ]);
        } else if (props.focused) {
            boxShadowColor = themePropertyFetcher(props.theme, [
                'colors',
                COLOR_VARIANT_TYPES.PRIMARY,
                'focus',
            ]);
        }

        const nonDisabledStyles = !props.disabled && {
            '&:hover': {
                backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 100]),
            },
        };

        const focusedStyles = props.focused && {
            backgroundColor: themePropertyFetcher(props.theme, [
                'colors',
                COLOR_VARIANT_TYPES.PRIMARY,
                'light',
            ]),
        };

        const disabledStyles = props.disabled && {
            backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 300]),
        };

        return {
            boxShadow: `inset 0 -2px 0 ${boxShadowColor}`,
            ...nonDisabledStyles,
            ...focusedStyles,
            ...disabledStyles,
        };
    }

    const nonDisabledStyles = !props.disabled &&
        !props.focused && {
            '&:hover': {
                backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 100]),
            },
        };

    const focusedStyles = props.focused && {
        backgroundColor: themePropertyFetcher(props.theme, [
            'colors',
            COLOR_VARIANT_TYPES.PRIMARY,
            'light',
        ]),
    };

    const disabledStyles = props.disabled && {
        backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 300]),
    };

    return {
        backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 200]),
        ...nonDisabledStyles,
        ...focusedStyles,
        ...disabledStyles,
    };
};

export const getTypeSpecificStyles = (props: IInputTextareaProps) => {
    if (props.as === 'textarea') {
        return {
            resize: 'none',
            // Due to the nature of the line-height of an input vs a textarea, shift the top-padding to vertically align the two variations
            paddingTop: 24,
        };
    }

    return {};
};
