import React, { FC } from 'react';

import { LoadingDots } from '../../icons';
import { withThemeContext } from '../../tools/helpers';
import { LoadingContainer, Button } from './styles';
import { IBaseButtonProps, VARIANT_TYPES } from './types';

export const BaseButtonPC: FC<IBaseButtonProps> = ({
    children = null,
    disabled = false,
    label,
    loading = false,
    onClick,
    variant = VARIANT_TYPES.CONTAINED,
    ...rest
}) => {
    const isCircular = variant === VARIANT_TYPES.CIRCULAR;

    const isDisabled = Boolean(disabled || loading);

    const onClickHandler = !isDisabled ? onClick : undefined;

    const content = children ?? label;
    if (!content) {
        return null;
    }

    // Adjusted padding
    const normalPadding = isCircular && content && Array.isArray(content);
    const loadingDotsWidth = isCircular && !normalPadding ? 32 : 40;

    return (
        <Button
            normalPadding={normalPadding}
            variant={variant}
            role="button"
            {...rest}
            disabled={isDisabled}
            isLoading={loading ? true : undefined}
            onClick={onClickHandler}
        >
            {loading && (
                <LoadingContainer disabled={isDisabled} variant={variant}>
                    <LoadingDots width={loadingDotsWidth} />
                </LoadingContainer>
            )}
            {content}
        </Button>
    );
};

export const BaseButtonWT = withThemeContext<IBaseButtonProps>(BaseButtonPC);
