import React, { FC } from 'react';

import { withThemeContext } from '../../tools/helpers';
import { InputEvent } from '../../types';
import { TabWT as Tab } from './tab';
import { Tabs } from './styles';
import { TabOption, ITabsProps } from './types';

export const TabsPC: FC<ITabsProps> = ({ onChange, value, tabStyle, options, ...rest }) => {
    /**
     * Handles onClick of each Tab element, if value of Tab value does not match provided props.value, fire onChange
     */
    const handleOnClick = (event: InputEvent) => {
        if (value.toString() !== (event.target as HTMLInputElement).value) {
            onChange(event, (event.target as HTMLButtonElement).value);
        }
    };

    return (
        <Tabs {...rest}>
            {options.map(({ value: val, icon, label, ...others }: TabOption) => (
                <Tab
                    key={val}
                    active={val === value}
                    icon={icon}
                    label={label}
                    onClick={handleOnClick}
                    value={val}
                    style={tabStyle}
                    {...others}
                />
            ))}
        </Tabs>
    );
};

export const TabsWT = withThemeContext<ITabsProps>(TabsPC);

export { TabWT } from './tab';
