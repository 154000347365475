import styled from 'styled-components';

import { themePropertyFetcher } from '../../tools/helpers';
import { IGenericStyledComponentProps, COLOR_VARIANT_TYPES, ColorVariantType } from '../../types';
import {
    CIRCULAR_BUTTON_VARIANT_TYPES,
    CircularButtonVariantType,
    VARIANT_TYPES,
    VariantType,
} from './types';

interface IButtonProps extends IGenericStyledComponentProps {
    normalPadding?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    variant: VariantType;
    colorVariant?: CircularButtonVariantType; // Only circularButton has a colorVariant at the moment
}

interface IIconContainerProps extends IGenericStyledComponentProps {
    left?: boolean;
    right?: boolean;
}

interface ILoadingContainerProps {
    disabled?: boolean;
    variant: VariantType;
}

export const Button = styled.button<IButtonProps>((props): any => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    padding: 0,

    color: props.isLoading ? 'transparent' : 'white',
    border: 'none',

    fontFamily: themePropertyFetcher(props.theme, ['fontFamily']),
    fontSize: themePropertyFetcher(props.theme, ['fontSizes', 2]),
    fontWeight: themePropertyFetcher(props.theme, ['fontWeights', 'bold']),
    letterSpacing: 1,

    cursor: props.disabled ? 'not-allowed' : 'pointer',

    /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
    transition: `color ease ${themePropertyFetcher(props.theme, [
        'transitionSpeeds',
        1,
    ])}, background-color ease ${themePropertyFetcher(props.theme, ['transitionSpeeds', 1])}`,

    svg: {
        transition: `none !important`,
    },

    ...getVariantStructureStyles(props),
    ...getVariantColorStyles(props),
}));

/**
 * Get structural styles based on props.variant
 */
const getVariantStructureStyles = (props: IButtonProps) => {
    const minHeight = 40;
    let minWidth = 96;
    let paddingLeft = themePropertyFetcher(props.theme, ['space', 4]);
    let paddingRight = themePropertyFetcher(props.theme, ['space', 4]);
    let borderRadius = themePropertyFetcher(props.theme, ['radii', 1]);

    if (props.variant === VARIANT_TYPES.CIRCULAR) {
        minWidth = 40;
        if (!props.normalPadding) {
            paddingLeft = (minWidth - 24) / 2;
            paddingRight = (minWidth - 24) / 2;
        }
        borderRadius = minHeight / 2;
    }

    return {
        minHeight,
        minWidth,
        paddingLeft,
        paddingRight,
        borderRadius,
    };
};

/**
 * Get color(+) styles based on props.variant
 * Prevent hover & focus when props.disabled is true.
 */
const getVariantColorStyles = (props: IButtonProps) => {
    const colorKey = getColorKeyFromVariant(props.variant);

    if (props.variant === VARIANT_TYPES.TEXT) {
        const disabledStyles = props.disabled && {
            '&:disabled': {
                color: props.isLoading
                    ? 'transparent'
                    : themePropertyFetcher(props.theme, ['colors', 'greys', 600]),
            },
            '&:focus': {
                outline: 'none',
            },
        };

        const nonDisabledStyles = !props.disabled && {
            '&:focus': {
                color: themePropertyFetcher(props.theme, ['colors', colorKey, 'focus']),
                outline: 'none',
            },
            '&:hover': {
                color: themePropertyFetcher(props.theme, ['colors', colorKey, 'hover']),
            },
        };

        return {
            border: '0 none',
            backgroundColor: 'transparent',
            color: themePropertyFetcher(props.theme, ['colors', colorKey, 'main']),
            ...nonDisabledStyles,
            ...disabledStyles,
        };
    } else if (props.variant === VARIANT_TYPES.OUTLINE) {
        const disabledStyles = props.disabled &&
            !props.isLoading && {
                '&:disabled': {
                    borderColor: themePropertyFetcher(props.theme, ['colors', 'greys', 500]),
                    color: themePropertyFetcher(props.theme, ['colors', 'greys', 600]),
                },
                '&:focus': {
                    outline: 'none',
                },
            };

        const loadingStyles = props.isLoading && {
            color: 'transparent',
        };

        const nonDisabledStyles = !props.disabled && {
            '&:focus': {
                borderColor: themePropertyFetcher(props.theme, ['colors', colorKey, 'focus']),
                color: themePropertyFetcher(props.theme, ['colors', colorKey, 'focus']),
                outline: 'none',
            },
            '&:hover': {
                borderColor: themePropertyFetcher(props.theme, ['colors', colorKey, 'hover']),
                backgroundColor: themePropertyFetcher(props.theme, ['colors', colorKey, 'hover']),
                color: 'white',
            },
        };

        return {
            borderColor: themePropertyFetcher(props.theme, ['colors', colorKey, 'main']),
            border: '1px solid',
            backgroundColor: 'transparent',
            color: props.isLoading
                ? 'transparent'
                : themePropertyFetcher(props.theme, ['colors', colorKey, 'main']),
            ...nonDisabledStyles,
            ...disabledStyles,
            ...loadingStyles,
        };
    } else if (
        props.variant === VARIANT_TYPES.CIRCULAR &&
        props.colorVariant === CIRCULAR_BUTTON_VARIANT_TYPES.GREY
    ) {
        const disabledStyles = props.disabled &&
            !props.isLoading && {
                '&:disabled': {
                    backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 200]),
                    color: themePropertyFetcher(props.theme, ['colors', 'greys', 300]),
                },
                '&:focus': {
                    outline: 'none',
                },
            };

        const nonDisabledStyles = !props.disabled && {
            '&:hover': {
                backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 200]),
                color: themePropertyFetcher(props.theme, ['colors', 'greys', 700]),
            },
            '&:focus': {
                backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 400]),
                color: themePropertyFetcher(props.theme, ['colors', 'greys', 1000]),
                outline: 'none',
            },
        };

        return {
            backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 300]),
            color: themePropertyFetcher(props.theme, ['colors', 'greys', 800]),
            ...nonDisabledStyles,
            ...disabledStyles,
        };
    } else {
        const disabledStyles = props.disabled &&
            !props.isLoading && {
                '&:disabled': {
                    backgroundColor: themePropertyFetcher(props.theme, [
                        'colors',
                        colorKey,
                        'disabled',
                    ]),
                    color: themePropertyFetcher(props.theme, ['colors', 'greys', 600]),
                },
                '&:focus': {
                    outline: 'none',
                },
            };

        const nonDisabledStyles = !props.disabled && {
            '&:hover': {
                backgroundColor: themePropertyFetcher(props.theme, ['colors', colorKey, 'hover']),
            },
            '&:focus': {
                backgroundColor: themePropertyFetcher(props.theme, ['colors', colorKey, 'focus']),
                outline: 'none',
            },
        };

        return {
            backgroundColor: themePropertyFetcher(props.theme, ['colors', colorKey, 'main']),
            ...nonDisabledStyles,
            ...disabledStyles,
        };
    }
};

/** Adds absolute positioned container for loader to avoid button width change */
export const LoadingContainer = styled.div<ILoadingContainerProps>((props): any => {
    const colorKey = getColorKeyFromVariant(props.variant);

    const color =
        props.variant === VARIANT_TYPES.OUTLINE || props.variant === VARIANT_TYPES.TEXT
            ? themePropertyFetcher(props.theme, ['colors', colorKey, 'main'])
            : 'white';

    return {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color,
    };
});

/**
 * Converts props.variant to DesignSystem friendly color key
 *
 * @param variant VariantType
 */
const getColorKeyFromVariant = (variant: VariantType): ColorVariantType => {
    switch (variant) {
        default:
        case VARIANT_TYPES.CONTAINED:
        case VARIANT_TYPES.OUTLINE:
        case VARIANT_TYPES.TEXT:
            return COLOR_VARIANT_TYPES.PRIMARY;
        case VARIANT_TYPES.DESTRUCTIVE:
            return COLOR_VARIANT_TYPES.ERROR;
    }
};

export const IconContainer = styled.span<IIconContainerProps>((props): any => {
    let styles = {};

    if (props.left) {
        styles = {
            marginLeft: -themePropertyFetcher(props.theme, ['space', 1]),
            marginRight: themePropertyFetcher(props.theme, ['space', 2]),
        };
    }

    if (props.right) {
        styles = {
            marginLeft: themePropertyFetcher(props.theme, ['space', 2]),
            marginRight: -themePropertyFetcher(props.theme, ['space', 1]),
        };
    }

    return styles;
});
