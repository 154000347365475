import React, { FC } from 'react';

import { Svg } from '../styles';
import { ISVG, ORIENTATION_TYPES } from '../types';

export const Caret: FC<ISVG> = ({
    fill = 'currentColor',
    size = '24px',
    orientation = ORIENTATION_TYPES.UP,
    styles,
    ...rest
}) => (
    <Svg
        role="img"
        {...rest}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        style={styles}
        orientation={orientation}
    >
        <path
            fill={fill}
            d="M6.7 16.41L12.08 11.12L17.29 16.4L18.71 15L12.1 8.29L5.3 14.99L6.7 16.41Z"
        />
    </Svg>
);
