import React, { FC } from 'react';

import { withThemeContext } from '../../tools/helpers';
import { Caret } from '../../icons';
import { ORIENTATION_TYPES } from '../../icons/types';
import { ExpandedContentToggle, TableSorter } from './styles';
import { IExpandedContentToggleProps, ITableSorterProps, SORT_ORDER_TYPES } from './types';

export const ExpandedContentTogglerPC: FC<IExpandedContentToggleProps> = ({
    expanded = false,
    label = 'View Details',
    onClick = () => null,
}) => (
    <ExpandedContentToggle onClick={onClick}>
        {label}
        <Caret size="20px" orientation={expanded ? ORIENTATION_TYPES.UP : ORIENTATION_TYPES.DOWN} />
    </ExpandedContentToggle>
);

export const ExpandedContentTogglerWT = withThemeContext<IExpandedContentToggleProps>(
    ExpandedContentTogglerPC,
);

export { ExpandedContentContainer } from './styles';

export const TableSorterPC: FC<ITableSorterProps> = ({ header, sortOptions }) => {
    const enabled =
        header.id === sortOptions.sortBy && sortOptions.sortOrder !== SORT_ORDER_TYPES.INACTIVE;
    return (
        <TableSorter enabled={enabled}>
            <Caret
                size="20px"
                orientation={
                    enabled && sortOptions.sortOrder === SORT_ORDER_TYPES.ASC
                        ? ORIENTATION_TYPES.UP
                        : ORIENTATION_TYPES.DOWN
                }
            />
        </TableSorter>
    );
};

export const TableSorterWT = withThemeContext<ITableSorterProps>(TableSorterPC);
