import React, { FC, useContext, ReactText } from 'react';
import { ThemeContext } from 'styled-components';

import { ISVG } from '../../icons/types';
import { Optionalize, IWithThemeProps } from '../../types';
import { IDesignSystem, DesignSystem } from '../../themes';

/**
 * Function which fetches nested value of provided theme with nested keys
 * i.e. theme.key1.key2.key3
 */

type Keys = [keyof IDesignSystem, ...ReactText[]];
type ObjectType = { [key: string]: ObjectType | ReactText };
type ValueType = ObjectType | ReactText;
type ThemePropertyFetcherType = (t: IDesignSystem, keys: Keys) => string | number;
const getValue = (inherit: string, keys: Keys, theme: IDesignSystem) => {
    let value = inherit as ValueType;
    for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
            const key = keys[i] as keyof IDesignSystem;
            value = theme[key] as ObjectType;
        } else {
            const key = keys[i] as ReactText;
            if (typeof value === 'object') {
                value = value[key];
            }
        }
    }

    return value;
};
export const themePropertyFetcher: ThemePropertyFetcherType = (
    t: IDesignSystem,
    keys: Keys,
): string | number => {
    const inherit = 'inherit';

    if (!Array.isArray(keys)) {
        return inherit;
    }

    // If provided theme does not contain first key, fallback with default theme
    const theme = t[keys[0]] ? t : (DesignSystem as IDesignSystem);

    const value = getValue(inherit, keys, theme);
    if (value !== null) {
        return value as string | number;
    }

    // If provided theme does not have value at provided key, fetch from default theme
    const defaultValue = getValue(inherit, keys, DesignSystem);

    return defaultValue as string | number;
};

/**
 * HOC to provide formatted theme prop based on style-components' ThemeContext
 *
 * @param WrappedComponent
 */
export function withThemeContext<P extends IWithThemeProps>(
    WrappedComponent: React.ComponentType<P>,
) {
    const ComponentWithTheme = (props: Optionalize<P, IWithThemeProps>) => {
        const theme = (useContext(ThemeContext) ?? DesignSystem) as IDesignSystem;
        return <WrappedComponent theme={theme} {...(props as P)} />;
    };
    ComponentWithTheme.displayName =
        (WrappedComponent.displayName ?? WrappedComponent.name) || 'Component';

    return ComponentWithTheme;
}

/**
 * Clone icon and replace size to ensure proper display of icon within Tab
 */
export const getFixedSizeIcon = (icon: FC<ISVG> | JSX.Element, size = '24px') =>
    React.isValidElement(icon) && React.cloneElement(icon as JSX.Element, { size });
