import styled from 'styled-components';

import { themePropertyFetcher } from '../tools/helpers';
import { ISVG, ORIENTATION_TYPES } from './types';

export const Svg = styled.svg<ISVG>((props): any => {
    let rotation = '';
    switch (props.orientation) {
        default:
        case ORIENTATION_TYPES.UP:
            rotation = '0deg';
            break;
        case ORIENTATION_TYPES.RIGHT:
            rotation = '90deg';
            break;
        case ORIENTATION_TYPES.DOWN:
            rotation = '180deg';
            break;
        case ORIENTATION_TYPES.LEFT:
            rotation = '270deg';
            break;
    }

    return {
        display: 'block',

        transform: `rotate(${rotation})`,
        transition: `all ease ${themePropertyFetcher(props.theme, ['transitionSpeeds', 1])}`,
    };
});
