import React, { FC, useState } from 'react';

import { withThemeContext } from '../../tools/helpers';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableHeaderContainer,
    TableBody,
    TableCell,
    TableCellVerticalSpacer,
} from './styles';
import { ITableProps, Header, Row, SORT_ORDER_TYPES, SortOrderType, SortOptions } from './types';
import { TableSorterWT as TableSorter } from './tableHelpers';

function getNextSortOrder(
    currentSortOptions: SortOptions,
    newSortBy: string | number,
): SortOrderType {
    // Possible sortOrder options
    const options = Object.keys(SORT_ORDER_TYPES) as SortOrderType[];
    // If less then the length of states && sortBy has not changed, add one else set to 0
    let i = options.indexOf(currentSortOptions.sortOrder);
    if (i < options.length - 1 && currentSortOptions.sortBy === newSortBy) {
        ++i;
    } else {
        i = 0;
    }

    return options[i];
}

export const TablePC: FC<ITableProps> = ({
    headers = [],
    rows = [],
    emptyCellValue = '—',
    sortOptions = { sortBy: headers[0].id, sortOrder: SORT_ORDER_TYPES.ASC },
    onSort,
}) => {
    const [currentSortOptions, setSortOptions] = useState(sortOptions);

    const sortEnabled = Boolean(onSort);

    const onClick = (sortBy: string | number) => () => {
        if (onSort) {
            const newSortOptions = {
                sortBy,
                sortOrder: getNextSortOrder(currentSortOptions, sortBy),
            };
            setSortOptions(newSortOptions);
            onSort(newSortOptions);
        }
    };

    const renderedHeaders = headers.map((header: Header) => {
        const sortable = sortEnabled && header.sortable;
        return (
            <TableHeader key={header.id} onClick={sortable ? onClick(header.id) : undefined}>
                <TableHeaderContainer>
                    {header.label}
                    {sortable && <TableSorter header={header} sortOptions={currentSortOptions} />}
                </TableHeaderContainer>
            </TableHeader>
        );
    });

    const renderedRows = rows.map((row: Row, i: number) => {
        const rowKey = `row-${row.id || `idx-${i}`}`;
        return (
            <React.Fragment key={rowKey}>
                <TableRow key={`${rowKey}-main`}>
                    {headers.map((header: Header) => (
                        <TableCell key={`${rowKey}_header-${header.id}`}>
                            <TableCellVerticalSpacer>
                                {row[header.id] || emptyCellValue}
                            </TableCellVerticalSpacer>
                        </TableCell>
                    ))}
                </TableRow>
                {row.expandedContent && (
                    <TableRow key={`${rowKey}-expanded`}>
                        <TableCell colSpan={headers.length} flush>
                            {row.expandedContent}
                        </TableCell>
                    </TableRow>
                )}
            </React.Fragment>
        );
    });

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>{renderedHeaders}</TableRow>
                </TableHead>
                <TableBody>{renderedRows}</TableBody>
            </Table>
        </TableContainer>
    );
};

export const TableWT = withThemeContext<ITableProps>(TablePC);

export {
    ExpandedContentContainer as TableExpandedContentContainer,
    ExpandedContentTogglerWT as TableExpandedContentToggler,
} from './tableHelpers';
