import React, { FC } from 'react';

import { withThemeContext, getFixedSizeIcon } from '../../tools/helpers';
import { Tab, TabIndicator, LabelContainer } from './styles';
import { ITabProps } from './types';

export const TabPC: FC<ITabProps> = ({ active = false, icon, label, onClick, value, ...rest }) => {
    const fixedSizeIcon = icon && getFixedSizeIcon(icon);

    if (!fixedSizeIcon && !label) {
        return null;
    }

    const labelContent = label && (
        <LabelContainer withIcon={Boolean(fixedSizeIcon)}>{label}</LabelContainer>
    );

    const withIconLabel = Boolean(fixedSizeIcon && label);

    return (
        <Tab
            active={active}
            onClick={onClick}
            role="tab"
            withIconLabel={withIconLabel}
            value={value}
            {...rest}
        >
            {fixedSizeIcon}
            {labelContent}
            {active && <TabIndicator />}
        </Tab>
    );
};

export const TabWT = withThemeContext<ITabProps>(TabPC);
