import { FC, ReactText } from 'react';

import { ISVG } from '../../icons/types';
import { InputEvent, IWithThemeProps } from '../../types';

export type VariantTypes = {
    FILLED: 'filled';
    OUTLINED: 'outlined';
};

export const VARIANT_TYPES: VariantTypes = {
    FILLED: 'filled',
    OUTLINED: 'outlined',
};

export type VariantType = VariantTypes[keyof VariantTypes];

interface ICommonProps extends IWithThemeProps {
    /**
     * Optional text to be displayed below input, can be used for error state or general use
     */
    assistiveText?: string;
    /**
     * Toggles disabled state
     */
    disabled?: boolean;
    /**
     * Toggles error state
     */
    hasError?: boolean;
    /**
     * Optional object of props to be provided to the input component
     */
    inputProps?: object;
    /**
     * Dynamic label which acts as placeholder on rest state
     */
    label: string;
    /**
     * Sets input.name attribute for reference
     */
    name: string;
    /**
     * Handles onChange event
     */
    onChange: (event: InputEvent) => void;
    /**
     * Toggles the required state; adds an asterisk to label
     */
    required?: boolean;
    /**
     * Optional style prop to append to component
     */
    style?: object;
    /**
     * Input's value controlled by external state
     */
    value?: ReactText;
    /**
     * Sets input variation for styling
     */
    variant?: VariantType;
}

export type TextFieldTypes = {
    EMAIL: 'email';
    NUMBER: 'number';
    PASSWORD: 'password';
    TEXT: 'text';
};

export const TEXT_FIELD_TYPES: TextFieldTypes = {
    EMAIL: 'email',
    NUMBER: 'number',
    PASSWORD: 'password',
    TEXT: 'text',
};

export type TextFieldType = TextFieldTypes[keyof TextFieldTypes];

export interface ITextFieldProps extends ICommonProps {
    /**
     * An optional icon prop for visual context or user interaction
     */
    icon?: FC<ISVG>;
    /**
     * Optional icon action to allow users to interact with icon. Not considered unless icon is provided
     */
    iconOnClick?: () => void;
    /**
     * Transforms type from input to textarea
     */
    multiline?: boolean;
    /**
     * Optional row count for when multiline is true. Note resize is set to 'none'
     */
    rows?: number;
    /**
     * Limited input type
     */
    type?: TextFieldType;
}

export type SelectOption = {
    label: string;
    value: ReactText;
    disabled?: boolean;
};

export interface ISelectProps extends ICommonProps {
    /**
     * Options to be rendered within select input
     */
    options: SelectOption[];
}
