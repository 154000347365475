import styled from 'styled-components';

import { themePropertyFetcher } from '../../tools/helpers';
import { IGenericStyledComponentProps } from '../../types';

export interface ITableCellProps extends IGenericStyledComponentProps {
    flush?: boolean;
}

export interface IExpandedContentToggleProps extends IGenericStyledComponentProps {
    onClick?: () => void;
}

export interface ITableHeaderProps extends IGenericStyledComponentProps {
    onClick?: () => void;
}

export interface ITableSorterProps extends IGenericStyledComponentProps {
    enabled: boolean;
}

export const TableContainer = styled.div<IGenericStyledComponentProps>({});

export const Table = styled.table<IGenericStyledComponentProps>({
    position: 'relative',
    width: '100%',

    backgroundColor: 'white',
    borderCollapse: 'collapse',

    textAlign: 'left',

    zIndex: 1,
});

export const TableHead = styled.thead<IGenericStyledComponentProps>((props): any => ({
    background: themePropertyFetcher(props.theme, ['colors', 'greys', 150]),
    border: `1px solid ${themePropertyFetcher(props.theme, ['colors', 'greys', 300])}`,
}));

export const TableSorter = styled.div<ITableSorterProps>((props): any => ({
    marginLeft: themePropertyFetcher(props.theme, ['space', 1]),
    color: themePropertyFetcher(props.theme, ['colors', 'greys', props.enabled ? 700 : 500]),

    opacity: !props.enabled ? 0 : 1,

    transition: `opacity ease ${themePropertyFetcher(props.theme, ['transitionSpeeds', 1])}`,
}));

export const TableHeader = styled.th<ITableHeaderProps>((props): any => ({
    padding: themePropertyFetcher(props.theme, ['space', 3]),
    '&:first-child': {
        paddingLeft: themePropertyFetcher(props.theme, ['space', 6]),
    },
    '&:last-child': {
        paddingRight: themePropertyFetcher(props.theme, ['space', 6]),
    },

    color: themePropertyFetcher(props.theme, ['colors', 'greys', 700]),

    fontSize: themePropertyFetcher(props.theme, ['fontSizes', 2]),
    fontWeight: themePropertyFetcher(props.theme, ['fontWeights', 'default']),

    cursor: props.onClick ? 'pointer' : 'inherit',

    '&:hover': {
        [TableSorter]: {
            opacity: 1,
        },
    },
}));

export const TableRow = styled.tr<IGenericStyledComponentProps>((props): any => ({
    borderBottom: `1px solid ${themePropertyFetcher(props.theme, ['colors', 'greys', 300])}`,
}));

export const TableHeaderContainer = styled.div<IGenericStyledComponentProps>({
    display: 'flex',
    alignItems: 'center',
});

export const TableBody = styled.tbody<IGenericStyledComponentProps>({});

export const TableCell = styled.td<ITableCellProps>((props): any => {
    let paddingObj;
    if (props.flush) {
        paddingObj = {
            padding: 0,
        };
    } else {
        paddingObj = {
            padding: themePropertyFetcher(props.theme, ['space', 3]),
            '&:first-child': {
                paddingLeft: themePropertyFetcher(props.theme, ['space', 6]),
            },
            '&:last-child': {
                paddingRight: themePropertyFetcher(props.theme, ['space', 6]),
            },
        };
    }

    return {
        minHeight: 24,

        ...paddingObj,

        fontSize: themePropertyFetcher(props.theme, ['fontSizes', 3]),
    };
});

export const TableCellVerticalSpacer = styled.div<IGenericStyledComponentProps>({
    display: 'flex',
    alignItems: 'center',
    minHeight: 26,
});

export const ExpandedContentContainer = styled.div<IGenericStyledComponentProps>((props): any => ({
    padding: themePropertyFetcher(props.theme, ['space', 6]),

    backgroundColor: themePropertyFetcher(props.theme, ['colors', 'greys', 75]),
    color: themePropertyFetcher(props.theme, ['colors', 'greys', 700]),
}));

export const ExpandedContentToggle = styled.div<IExpandedContentToggleProps>((props): any => ({
    display: 'flex',
    alignItems: 'center',

    color: themePropertyFetcher(props.theme, ['colors', 'greys', 800]),

    cursor: props.onClick ? 'pointer' : 'inherit',

    '> svg': {
        marginLeft: themePropertyFetcher(props.theme, ['space', 2]),
    },
}));
