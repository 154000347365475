import styled from 'styled-components';

import { themePropertyFetcher } from '../../tools/helpers';
import { IGenericStyledComponentProps, COLOR_VARIANT_TYPES } from '../../types';
import { ITabProps } from './types';

interface ITabSCProps extends ITabProps {
    withIconLabel: boolean;
}

interface ILabelContainer extends IGenericStyledComponentProps {
    withIcon: boolean;
}

export const Tabs = styled.div<IGenericStyledComponentProps>({
    display: 'flex',
    flexDirection: 'row',
});

export const Tab = styled.button<ITabSCProps>((props): any => {
    return {
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        minHeight: props.withIconLabel ? 72 : 48,

        paddingTop: themePropertyFetcher(props.theme, ['space', 2]),
        paddingRight: themePropertyFetcher(props.theme, ['space', 4]),
        paddingBottom: `calc(${themePropertyFetcher(props.theme, ['space', 2])}px + 2px)`, // Compensate for TabIndicator
        paddingLeft: themePropertyFetcher(props.theme, ['space', 4]),

        border: 'none',

        fontSize: themePropertyFetcher(props.theme, ['fontSizes', 2]),
        fontWeight: themePropertyFetcher(props.theme, ['fontWeights', 'bold']),
        letterSpacing: 1,

        color: themePropertyFetcher(
            props.theme,
            props.active
                ? ['colors', COLOR_VARIANT_TYPES.PRIMARY, 'main']
                : ['colors', 'greys', 800],
        ),

        transition: `all ease ${themePropertyFetcher(props.theme, ['transitionSpeeds', 1])}`,
        svg: {
            transition: `none !important`,
        },

        cursor: 'pointer',

        '&:hover': {
            color: themePropertyFetcher(
                props.theme,
                props.active
                    ? ['colors', COLOR_VARIANT_TYPES.PRIMARY, 'hover']
                    : ['colors', 'greys', 700],
            ),
            backgroundColor: themePropertyFetcher(
                props.theme,
                props.active
                    ? ['colors', COLOR_VARIANT_TYPES.PRIMARY, 'light']
                    : ['colors', 'greys', 200],
            ),

            [TabIndicator]: {
                backgroundColor: themePropertyFetcher(props.theme, [
                    'colors',
                    COLOR_VARIANT_TYPES.PRIMARY,
                    'hover',
                ]),
            },
        },

        '&:focus': {
            outline: 'none',
        },

        '*': {
            pointerEvents: 'none',
        },
    };
});

export const TabIndicator = styled.div<IGenericStyledComponentProps>((props): any => {
    return {
        position: 'absolute',
        bottom: 0,

        width: '100%',
        height: 2,

        backgroundColor: themePropertyFetcher(props.theme, [
            'colors',
            COLOR_VARIANT_TYPES.PRIMARY,
            'main',
        ]),

        transition: `all ease ${themePropertyFetcher(props.theme, ['transitionSpeeds', 1])}`,
    };
});

export const LabelContainer = styled.div<ILabelContainer>((props): any => {
    return {
        paddingTop: props.withIcon ? themePropertyFetcher(props.theme, ['space', 2]) : 0,
    };
});
