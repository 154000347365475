import React, { FC } from 'react';

import { Svg } from '../styles';
import { ILoadingDotsProps } from '../types';

export const LoadingDots: FC<ILoadingDotsProps> = ({
    fill = 'currentColor',
    beginStepSize = 0.1,
    duration = 1,
    height = 20,
    width = 60,
    ...rest
}) => (
    <Svg role="img" {...rest} viewBox="0 0 60 20" fill={fill} width={width} height={height}>
        <circle cx="25%" cy="10" r="4">
            <animate
                attributeName="opacity"
                dur={`${duration || 1}s`}
                values="0;1;0"
                repeatCount="indefinite"
                begin={beginStepSize}
            />
        </circle>
        <circle cx="50%" cy="10" r="4">
            <animate
                attributeName="opacity"
                dur={`${duration || 1}s`}
                values="0;1;0"
                repeatCount="indefinite"
                begin={(beginStepSize || 0.1) * 2}
            />
        </circle>
        <circle cx="75%" cy="10" r="4">
            <animate
                attributeName="opacity"
                dur={`${duration || 1}s`}
                values="0;1;0"
                repeatCount="indefinite"
                begin={(beginStepSize || 0.1) * 3}
            />
        </circle>
    </Svg>
);
