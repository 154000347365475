import styled, { css, keyframes, FlattenSimpleInterpolation } from 'styled-components';

import { themePropertyFetcher } from '../../tools/helpers';
import { IGenericStyledComponentProps, COLOR_VARIANT_TYPES } from '../../types';

interface ICircleProps extends IGenericStyledComponentProps {
    progressStroke: boolean;
}

interface IContainerProps extends IGenericStyledComponentProps {
    loadingAnimation: boolean;
    loadingAnimationSpeed: string;
    loadingAnimationEase: string;
}

export const Circle = styled.circle<ICircleProps>((props): any => {
    return {
        stroke: themePropertyFetcher(props.theme, [
            'colors',
            COLOR_VARIANT_TYPES.PRIMARY,
            props.progressStroke ? 'main' : 'light',
        ]),
    };
});

const rotateKeyFrames = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const Svg = styled.svg<IGenericStyledComponentProps>(() => ({
    display: 'block',
}));

export const Container = styled.div<IContainerProps>(
    (props): FlattenSimpleInterpolation => css`
        width: fit-content;
        animation: ${props.loadingAnimation && rotateKeyFrames} ${props.loadingAnimationSpeed}
            ${props.loadingAnimationEase} infinite;
    `,
);
