export type OrientationTypes = {
    UP: 'up';
    DOWN: 'down';
    LEFT: 'left';
    RIGHT: 'right';
};

export const ORIENTATION_TYPES: OrientationTypes = {
    UP: 'up',
    DOWN: 'down',
    LEFT: 'left',
    RIGHT: 'right',
};

export type OrientationType = OrientationTypes[keyof OrientationTypes];

export interface ISVG {
    /**
     * Defines the color of the icon
     */
    fill?: string;
    /**
     * Sets the size (width & height) of icon
     */
    size?: string;
    /**
     * Optional style prop to append to component
     */
    styles?: object;
    /**
     * Optional orientation prop to rotate the icon
     */
    orientation?: OrientationType;
}

export interface ILoadingDotsProps extends ISVG {
    /**
     * Set when will dots begin their animation, each dot begins at beginStepSize * dot index
     */
    beginStepSize?: number;
    /**
     * Set the duration of the animation, in seconds
     */
    duration?: number;
    /**
     * Set the height of the dots, in pixels
     */
    height?: number;
    /**
     * Set the height of the dots, in pixels
     */
    width?: number;
}
