import { IDesignSystem } from './types';

/*
  This defaultTheme file follows System UI Theme Specification
  https://system-ui.com/defaultTheme/
*/

const colorObject = {
    greys: {
        50: '#FFFFFF',
        75: '#FCFCFC', // Not in spec but used for table
        100: '#F6F8FA',
        150: '#F2F2F2',
        200: '#E7EAED',
        300: '#D6DADD',
        400: '#C4CACF',
        500: '#A3ADB6',
        600: '#7A8289',
        700: '#5E6469',
        800: '#414549',
        900: '#1F2225',
        1000: '#000000',
    },
    yellows: {
        100: '#F6E191',
        200: '#FCDC61',
        300: '#FFCB05',
        400: '#C89E01',
        700: '#997900',
    },
    reds: {
        300: '#CD2F4C',
        500: '#BF0023',
        700: '#8B001A',
    },
    greens: {
        300: '#36D100',
        500: '#238700',
        700: '#196100',
    },
    blues: {
        100: '#EBF3FF',
        200: '#589BFF',
        300: '#0066FF',
        400: '#0051CB',
        700: '#002A68',
    },
};

export const DesignSystem: IDesignSystem = {
    name: 'Design System',

    space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52],

    fontFamily: '"Roboto", sans-serif',
    fontSizes: [0, 12, 14, 16, 18, 20, 32, 40],
    fontWeights: { default: 400, medium: 500, bold: 700 },
    lineHeights: { single: 1.5, default: 1.5, double: 2 },

    transitionSpeeds: ['0s', '0.2s', '1s'],

    radii: [0, 4, 8, 12],

    colors: {
        ...colorObject,
        primary: {
            light: colorObject.blues[100],
            main: colorObject.blues[300],
            hover: colorObject.blues[200],
            focus: colorObject.blues[400],
            dark: colorObject.blues[700],
            disabled: colorObject.greys[100],
        },
        secondary: {
            light: colorObject.yellows[100],
            main: colorObject.yellows[300],
            hover: colorObject.yellows[200],
            focus: colorObject.yellows[400],
            dark: colorObject.yellows[700],
            disabled: colorObject.greys[100],
        },
        error: {
            hover: colorObject.reds[300],
            main: colorObject.reds[500],
            focus: colorObject.reds[700],
            disabled: colorObject.greys[100],
        },
        success: {
            hover: colorObject.greens[300],
            main: colorObject.greens[500],
            focus: colorObject.greens[700],
            disabled: colorObject.greys[100],
        },
    },
};
