import { ReactNode } from 'react';

import { InputEvent, IWithThemeProps } from '../../types';

export type CircularButtonVariantTypes = {
    PRIMARY: 'primary';
    GREY: 'grey';
};
export const CIRCULAR_BUTTON_VARIANT_TYPES: CircularButtonVariantTypes = {
    PRIMARY: 'primary',
    GREY: 'grey',
};

export type CircularButtonVariantType = CircularButtonVariantTypes[keyof CircularButtonVariantTypes];

export interface IButtonProps extends IWithThemeProps {
    /**
     * Displays the provided children as the button's label
     */
    children?: ReactNode;
    /**
     * Optional color variant to set background color of button with variant set to 'circular'
     */
    colorVariant?: CircularButtonVariantType;
    /**
     * Toggles displayed state
     */
    disabled?: boolean;
    /**
     * Displays content within button, for closed tag use
     */
    label?: ReactNode;
    /**
     * Toggles loading state
     */
    loading?: boolean;
    /**
     * Handles onClick event
     */
    onClick?: (event: InputEvent) => void;
    /**
     * Optional style prop to append to component
     */
    style?: object;
}

export type VariantTypes = {
    CONTAINED: 'contained';
    DESTRUCTIVE: 'destructive';
    OUTLINE: 'outline';
    TEXT: 'text';
    CIRCULAR: 'circular';
};

export const VARIANT_TYPES: VariantTypes = {
    CONTAINED: 'contained',
    DESTRUCTIVE: 'destructive',
    OUTLINE: 'outline',
    TEXT: 'text',
    CIRCULAR: 'circular',
};

export type VariantType = VariantTypes[keyof VariantTypes];

export interface IBaseButtonProps extends IButtonProps {
    /**
     * Sets button variation for styling
     */
    variant?: VariantType;
}

export interface ICircularButton extends IButtonProps {
    /**
     * Optional color variant to set background color of circularButton
     */
    colorVariant?: CircularButtonVariantType;
}

export interface IIconPrefixSuffix extends IWithThemeProps {
    /**
     * A ReactNode to be displayed with the appropriate margin
     */
    icon: ReactNode;
}
