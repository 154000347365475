import { IDesignSystem } from '../themes/types';

export type Optionalize<T extends K, K> = Omit<T, keyof K>;
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type ColorVariantTypes = {
    PRIMARY: 'primary';
    SECONDARY: 'secondary';
    ERROR: 'error';
    SUCCESS: 'success';
};

export const COLOR_VARIANT_TYPES: ColorVariantTypes = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    ERROR: 'error',
    SUCCESS: 'success',
};

export type ColorVariantType = ColorVariantTypes[keyof ColorVariantTypes];

export interface IWithThemeProps {
    /**
     * Design System to style components
     */
    theme?: IDesignSystem;
}

export interface IGenericStyledComponentProps {
    /**
     * Design System to style components
     */
    theme: IDesignSystem;
}

export type InputEvent =
    | React.MouseEvent<HTMLInputElement | HTMLButtonElement, MouseEvent>
    | React.ChangeEvent<HTMLInputElement | HTMLButtonElement>
    | React.FormEvent;
